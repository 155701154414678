<template>
  <div id="user-edit-tab-account">
    <!-- Avatar Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img class="user-profile-img" :src="dataLocal.photo" />
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <vs-label text="Name" />
          <vs-input
            v-model="dataLocal.name"
            v-validate="'required|alpha_spaces'"
            class="w-full"
            name="name"
          />
          <v-error :err="errors" field="name" />
        </div>

        <div class="mt-4">
          <vs-label text="Email" />
          <vs-input
            v-model="dataLocal.email"
            v-validate="'required|email'"
            class="w-full"
            type="email"
            name="email"
          />
          <v-error :err="errors" field="email" />
        </div>

        <div class="mt-4">
          <vs-label text="Mobile" />
          <vs-input v-model="dataLocal.mobile" class="w-full" name="mobile" />
        </div>

        <div class="mt-4">
          <vs-label text="Country" />
          <v-select
            v-model="countryLocal"
            v-validate="'required'"
            :clearable="false"
            :options="countryList"
            name="country"
          />
          <v-error :err="errors" field="country" />
        </div>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <vs-label text="Status" />
          <v-select
            v-model="statusLocal"
            v-validate="'required'"
            :clearable="false"
            :options="userStatusList"
            name="status"
          />
          <v-error :err="errors" field="status" />
        </div>

        <div class="mt-4">
          <vs-label text="Role" />
          <v-select
            v-model="roleLocal"
            v-validate="'required'"
            :clearable="false"
            :options="userRoleList"
            name="role"
          />
          <v-error :err="errors" field="role" />
        </div>

        <div class="mt-4">
          <vs-label text="Region" />
          <v-select
            v-model="regionLocal"
            :clearable="false"
            :options="regionList"
            name="region"
          />
        </div>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button
            v-if="$gate.can('edit_users')"
            class="ml-auto mt-2"
            :disabled="!validateForm"
            @click="save"
          >
            Save Changes
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import vSelect from 'vue-select';

import { getMessageFromError } from '@/helpers';
import { UserAction } from '@/store/actionTypes';

import userTabAccountMixin from '@/mixins/userTabAccountMixin';

import vError from '@/components/VError';

export default {
  components: {
    vError,
    vSelect,
  },
  mixins: [userTabAccountMixin],
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    countryLocal: {
      get() {
        return this.countryList.find(
          ({ value }) => value === this.dataLocal.country
        );
      },
      set(obj) {
        this.dataLocal.country = obj.value;
      },
    },
    statusLocal: {
      get() {
        return this.userStatusList.find(
          ({ value }) => value === this.dataLocal.status
        );
      },
      set(obj) {
        this.dataLocal.status = obj.value;
      },
    },
    roleLocal: {
      get() {
        return this.userRoleList.find(
          ({ value }) => value === this.dataLocal.role
        );
      },
      set(obj) {
        this.dataLocal.role = obj.value;
      },
    },
    regionLocal: {
      get() {
        return this.regionList.find(
          ({ value }) => value === this.dataLocal.region
        );
      },
      set(obj) {
        this.dataLocal.region = obj.value;
      },
    },
    userId() {
      return +this.$route.params.userId;
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.dataLocal.name !== null &&
        this.dataLocal.name !== '' &&
        this.dataLocal.email !== null &&
        this.dataLocal.email !== '' &&
        this.dataLocal.country !== null &&
        this.dataLocal.role !== null
      );
    },
  },
  methods: {
    ...mapActions('user', [UserAction.update]),

    async save() {
      if (!this.$gate.can('edit_users')) {
        return;
      }

      this.$vs.loading();

      try {
        const { name, email, mobile, status, country, role, region } =
          this.dataLocal;

        await this.updateUser({
          id: this.userId,
          name,
          email,
          mobile,
          status,
          country,
          role,
          region,
        });

        this.notifySuccess(
          'User updated',
          'The user has been successfully updated.'
        );
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile-img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}
</style>
