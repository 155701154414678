<template>
  <div id="page-user-edit">
    <vs-alert color="danger" title="User Not Found" :active.sync="userNotFound">
      <span>User record with id: {{ $route.params.userId }} not found.</span>
      <span>
        <span>Check</span>
        <router-link
          :to="{ name: 'users-list' }"
          class="text-inherit underline"
        >
          All Users
        </router-link>
      </span>
    </vs-alert>

    <vx-card v-if="userData">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <h4 class="mb-4">{{ userData.name }}</h4>

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <user-edit-tab-account class="mt-4" :data="userData" />
            </div>
          </vs-tab>

          <vs-tab label="Permissions" icon-pack="feather" icon="icon-lock">
            <div class="tab-text">
              <user-edit-tab-permissions class="mt-4" :data="userData" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { UserAction } from '@/store/actionTypes';

import moduleUser from '@/store/users/moduleUser';

import UserEditTabAccount from './UserEditTabAccount';
import UserEditTabPermissions from './UserEditTabPermissions';

export default {
  components: {
    UserEditTabAccount,
    UserEditTabPermissions,
  },
  data() {
    return {
      userData: null,
      userNotFound: false,
      activeTab: 0,
    };
  },
  computed: {
    userId() {
      return +this.$route.params.userId;
    },
  },
  watch: {
    activeTab() {
      this.fetchUserById(this.$route.params.userId);
    },
  },
  methods: {
    ...mapActions('user', [UserAction.fetchOne]),

    registerStoreModules() {
      if (!moduleUser.isRegistered) {
        this.$store.registerModule('user', moduleUser);
        moduleUser.isRegistered = true;
      }
    },
    async fetchUserById(userId) {
      this.$vs.loading();

      try {
        this.userData = await this.fetchUser(userId);
      } catch (error) {
        this.notifyError(error, 'Problem with loading a user.');

        if (error.response.status === 404) {
          this.userNotFound = true;
          return;
        }
      }

      this.$vs.loading.close();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    await this.fetchUserById(this.userId);

    this.$vs.loading.close();
  },
};
</script>
